import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {ThemeProvider} from 'styled-components';
import {useSelector} from 'react-redux';
import {uiSelector} from 'state/ui';
import Helmet from 'react-helmet'
import Header from 'components/Header';
import Footer from 'components/Footer';

import GlobalStyles from 'assets/styles/globalStyles';
import * as Theme from 'assets/styles/theme';
import {LayoutWrapper, MainWrapper} from './styles';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import favicon from '../../assets/images/favicon-192x192.png';

interface Props {
    children: React.ReactNode;
}

/**
 * Layout component
 *
 * @param {Props} props
 */
const Layout: React.FC<Props> = ({children}) => {

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    const {themeMode} = useSelector(uiSelector);

    return (
        <ThemeProvider theme={Theme[themeMode]}>

            <GlobalStyles/>

            <LayoutWrapper className="home">

                <Helmet>

                    <link rel="icon" href={favicon}/>

                </Helmet>

                <Header siteTitle={data.site.siteMetadata.title}/>

                {children}

                <Footer/>

            </LayoutWrapper>

        </ThemeProvider>
    );
};

export default Layout;
